import React, { useCallback, useEffect, useState, useContext } from 'react';
import Card from 'components/inputs/Card';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import DummyImage from 'assets/images/icons/dummyImage.png';
import Avatar from 'components/inputs/Avatar';
import CustomerProfile from './CustomerProfile';
import DropDown from 'components/inputs/DropDown';
import { BsThreeDots } from 'react-icons/bs';
import { BsDot } from 'react-icons/bs';
import {
  FETCH_CUSTOMER_PROFILE,
  GET_CUSTOMER,
  UPDATE_CUSTOMER,
  WALLET_TRANSACTION,
  SYNC_WALLET,
  FETCH_ROLES,
  RESET_OTP_LIMIT,
  UPDATE_CUSTOMER_VEHICLE,
} from 'actions/customer';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import CustomerBooking from './CustomerBooking';
import CustomerTransaction from './CustomerTransaction';
import CustomerFaultyTransaction from './CustomerFaultyTransaction';
import { AiOutlineCar } from 'react-icons/ai';
import { CREATE_STATUS_CODE, GET_CUSTOMERS, MANAGE_CUSTOMERS, UPDATE_STATUS_CODE } from 'components/common/constant';
import { Modal } from 'react-bootstrap';
import { MdClose } from 'react-icons/md';
import { Form, Formik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import * as Yup from 'yup';
import Select from 'components/inputs/Select';
import Button from 'components/inputs/Button';
import { toast } from 'react-toastify';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import RfidDetailsModal from './RfidDetailsModal';
// import { FETCH_ROLE } from 'actions/role';
// import { CHANGE_ROLE } from 'actions/user';
// import { TENANT_ADMIN, ADMIN } from 'components/common/constant';
import ChargeCoinTransaction from './ChargeCoinTransaction';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import ToggleSwitch from 'components/inputs/ToggleSwitch';
import { UPDATE_VEHICLE } from 'actions/vehicle';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { UPDATE_USER } from 'actions/user';
import ScheduleBookingForm from './BookingForm';

const CustomerDetail = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { customerId } = useParams();
  const { navigateTo, isAllowed } = useContext(NavContext);
  const [currentTab, setCurrentTab] = useState('bookings');
  const allRoles = useSelector((state) => state.customer.role);
  const profileData = useSelector((state) => state.profile.userProfile);
  const [showWalletForm, setShowWalletForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showRoleForm, setShowRoleForm] = useState(false);
  const [showEditVehicleForm, setShowEditVehicleForm] = useState(false);
  const [vehicleId, setVehicleId] = useState('');
  const [showBookingForm, setShowBookingForm] = useState(false);
  const [showModal, setShowModal] = useState('');
  const isLoader = useSelector((state) => state.profile.isLoading);
  const customerProfileList = useSelector((state) => state.customerProfile.customerProfile);
  const isLoading = useSelector((state) => state.customerProfile.isLoading);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);
  const currentUserName = _.get(profileData, 'name', '');
  const isDeleted = _.get(customerProfileList, 'deleted');
  const currentVehicle = vehicleId && customerProfileList?.vehicles.filter((item) => item.id === vehicleId);

  const getCustomerProfile = useCallback((id) => {
    dispatch({ type: FETCH_CUSTOMER_PROFILE, payload: id });
  }, []);

  const getAllRoles = useCallback((data) => {
    dispatch({ type: FETCH_ROLES, payload: data });
  }, []);

  // const editUserRole = useCallback(
  //   (role, userId) => {
  //     const userRole = {
  //       id: userId,
  //       role: role,
  //     };
  //     dispatch({ type: CHANGE_ROLE, payload: userRole });
  //   },
  //   [allRoles]
  // );

  useEffect(() => {
    getAllRoles();
  }, [customerId]);

  const updateCustomer = useCallback(
    (data) => {
      const customerData = {
        ...data,
        id: customerId,
      };
      dispatch({
        type: UPDATE_USER,
        payload: customerData,
        cb: (res) => {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            toast.success('Customer updated successfully');
            setTimeout(() => {
              window.location.reload();
            }, 500);
            navigateTo(`/customerProfile/${customerId}`);
          }
        },
      });
    },
    [customerId]
  );

  const suspendCustomer = useCallback(
    (data) => {
      const customerData = {
        ...data,
        id: customerId,
      };
      dispatch({
        type: UPDATE_CUSTOMER,
        payload: customerData,
        cb: (res) => {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            // toast.success('Customer updated successfully');
            navigateTo(`/customerProfile/${customerId}`);
          }
        },
      });
    },
    [customerId]
  );

  const autoChargeCustomer = useCallback(
    (data) => {
      dispatch({
        type: UPDATE_VEHICLE,
        payload: data,
        cb: (res) => {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            navigateTo(`/customerProfile/${customerId}`);
          }
        },
      });
    },
    [customerId]
  );

  const getCustomerDetail = useCallback((id) => {
    dispatch({ type: GET_CUSTOMER, payload: id });
  });

  const walletTransaction = useCallback(
    (data = {}) => {
      dispatch({
        type: WALLET_TRANSACTION,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === CREATE_STATUS_CODE) {
              toast.success('Transaction in wallet successfully');
              setShowWalletForm(false);
              
              // Reload the customer profile data after wallet transaction is successful
              getCustomerProfile(customerId);
  
              // Navigate to the customer's profile page if needed
              navigateTo(`/customerProfile/${customerId}`);
            }
          }
        },
      });
    },
    [customerId, getCustomerProfile, navigateTo]
  );

  const editVehicleDetail = useCallback(
    (data) => {
      dispatch({
        type: UPDATE_CUSTOMER_VEHICLE,
        payload: { ...data },
        cb: (res) => {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            toast.success('EVCC ID updated successfully');
            setTimeout(() => {
              window.location.reload();
              navigateTo(`/customerProfile/${customerId}`);
            }, 1000);
          }
        },
      });
    },
    [customerId]
  );

  const syncWallet = useCallback(
    (data) => {
      dispatch({
        type: SYNC_WALLET,
        payload: data,
        cb: (res) => {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            toast.success('Wallet Synced Successfully');
            setTimeout(() => {
              setCurrentTab('transactions');
            }, 1000);
            navigateTo(`/customerProfile/${customerId}`);
          }
        },
      });
    },
    [customerId]
  );

  const resetOTPLimit = useCallback(
    (data) => {
      dispatch({
        type: RESET_OTP_LIMIT,
        payload: data,
        cb: (res) => {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            toast.success('Reset max OTP limit successfully');
            setTimeout(() => {
              window.location.reload();
              navigateTo(`/customerProfile/${customerId}`);
            }, 1000);
          }
        },
      });
    },
    [customerId]
  );

  useEffect(() => {
    getCustomerProfile(customerId);
  }, [customerId, getCustomerProfile]);

  const setTab = (e, tab) => {
    e.preventDefault();
    setCurrentTab(tab);
  };

  const handleClose = () => {
    setShowWalletForm(false);
    setShowEditForm(false);
    setShowRoleForm(false);
    setShowModal(false);
    setShowBookingForm(false);
    setShowEditVehicleForm(false);
  };

  const getCustomerPermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_CUSTOMERS),
      (data) => data.name
    );

  const manageCustomerPermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_CUSTOMERS),
      (data) => data.name
    );

  const WalletTransactionSchema = Yup.object().shape({
    message: Yup.string().required(t('customerDetail.message')),
    type: Yup.string().required(t('customerDetail.type')),
    amount: Yup.number().required(t('customerDetail.amount')),
  });

  const CustomerSchema = Yup.object().shape({
    // first_name: Yup.string().required(t('customerDetail.firstName')).max(100, t('customerDetail.maxlimit')),
    // last_name: Yup.string().required(t('customerDetail.lastName')).max(100, t('customerDetail.maxlimit')),
    username: Yup.string().required(t('customerForm.username')).min(8, t('customerForm.minLength')),
    // password: Yup.string().required(t('customerForm.password')),
  });

  const VehicleSchema = Yup.object().shape({
    evcc_id: Yup.string().required(t('customerDetail.validationEvcc')),
  });

  return (
    <React.Fragment>
      <LayoutContainer className="layout-container-main-page">
        <AdminHeader title={t('header.customerProfile')} backTo="/customers" />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="customerDetail-page__main">
              <Card>
                <div className="page--info">
                  <div className="page--info--inner">
                    <div className="page-avatar">
                      {isLoader ? <Skeleton height="20" width="20" circle="true" /> : <Avatar imgSrc={DummyImage} name="userProfile" />}
                    </div>
                    <div className="customer-content">
                      <span className="customer-name">
                        {isLoading ? <Skeleton height="20" width="100" /> : _.get(customerProfileList, 'name', 'Guest User')}
                      </span>
                      <div className="customer-role">
                        {/* {isLoader ? (
                          <Skeleton height="20" width="100" />
                        ) : _.get(profileData, 'role', '') == TENANT_ADMIN || ADMIN ? (
                          <Select
                            className="customer_roles-select__box"
                            value={isLoading ? <Skeleton height="20" width="100" /> : _.get(customerProfileList, 'role', '')}
                            options={_.map(allRoles, (item) => {
                              return { label: _.startCase(item.name), value: item.name };
                            })}
                            onChange={(val) => {
                              editUserRole(val, customerProfileList.id);
                              location.reload();
                            }}
                          ></Select>
                        ) : (
                          <span className="customer-info">
                            {isLoading ? <Skeleton height="20" width="100" /> : _.get(customerProfileList, 'role', '')}
                          </span>
                        )} */}
                      </div>
                    </div>
                    {isDeleted === true && (
                      <div className="customer-detail-action__block">
                        <div className="customer-detail--block">
                          <div className="customer--detail">
                            <span>
                              <BsDot className="dot--icon" />
                              {t('addCustomerList.delete')}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {(!_.isEmpty(manageCustomerPermissions) || (!_.isEmpty(getCustomerPermissions) && !_.isEmpty(manageCustomerPermissions))) && (
                    <div className="customerDetail-dropdown_btn">
                      {!isDeleted === true && (
                        <DropDown
                          trigger={
                            <div className="dropdown-icon">
                              <BsThreeDots />
                            </div>
                          }
                          popupClass="bottom-left"
                        >
                          <ul>
                            {isLoading ? (
                              <Skeleton height="20" width="100" />
                            ) : _.get(customerProfileList, 'isActive') ? (
                              <li
                                className="item"
                                onClick={() => {
                                  suspendCustomer({ isActive: false });
                                }}
                              >
                                {t('customerDetail.suspend')}
                              </li>
                            ) : (
                              <li
                                className="item"
                                onClick={() => {
                                  suspendCustomer({ isActive: true });
                                }}
                              >
                                {t('customerDetail.active')}
                              </li>
                            )}
                            {/* <li
                        className="item"
                        onClick={() => {
                          setValue(_.get(customerProfileList, 'isActive'));
                          suspendCustomer(!value);
                        }}
                      >
                        {_.get(customerProfileList, 'isActive')? 'Suspend' : 'Active'}
                      </li> */}
                            {isAllowed('scheduleBookingCustomerManagement') && (
                              <li
                                className="item"
                                onClick={() => {
                                  setShowBookingForm(true);
                                }}
                              >
                                {t('customerDetail.scheduleBooking')}
                              </li>
                            )}
                            <li className="item" onClick={() => setShowEditForm(true)}>
                              {t('customerDetail.edit')}
                            </li>
                            {isAllowed('syncWalletBalanceCustomerManagement') && (
                              <li className="item" onClick={() => syncWallet({ id: customerId })}>
                                {t('customerDetail.syncWallet')}
                              </li>
                            )}
                            {isAllowed('updateWalletBalanceCustomerManagement') && (
                              <li className="item" onClick={() => setShowWalletForm(true)}>
                                {t('customerDetail.updateWallet')}
                              </li>
                            )}
                            {isAllowed('assignRFIDCustomerManagement') && (
                              <li
                                className="item"
                                onClick={() => {
                                  setShowModal(true);
                                  getCustomerDetail(customerId);
                                }}
                              >
                                {_.isEmpty(_.get(customerProfileList, 'rfid')) ? t('customerDetail.assignRFID') : t('customerDetail.manageRFID')}
                              </li>
                            )}
                            {isAllowed('changeRoleCustomerManagement') && (
                              <li className="item" onClick={() => setShowRoleForm(true)}>
                                {t('customerDetail.changeRole')}
                              </li>
                            )}
                            {isAllowed('reset_otp') && (
                              <li className="item" onClick={() => resetOTPLimit({ number: _.get(customerProfileList, 'phone') })}>
                                {t('customerDetail.resetOTP')}
                              </li>
                            )}
                          </ul>
                        </DropDown>
                      )}
                    </div>
                  )}
                </div>
                <div>
                  <div className="tab-block">
                    <div className="tab-nav-block">
                      <a
                        href="#"
                        className={`tab-nav__item ${currentTab === 'profile' ? 'active' : ''}`}
                        onClick={(e) => setTab(e, 'profile')}
                        data-content="tab-profile"
                      >
                        {isLoading ? <Skeleton height="20" width="100" /> : t('customerDetail.tabProfile')}
                      </a>
                      {isAllowed('viewBookingsCustomerManagement') && (
                        <a
                          href="#"
                          className={`tab-nav__item ${currentTab === 'bookings' ? 'active' : ''}`}
                          onClick={(e) => setTab(e, 'bookings')}
                          data-content="tab-bookings"
                        >
                          {isLoading ? <Skeleton height="20" width="100" /> : t('customerDetail.tabBookings')}
                        </a>
                      )}
                      {isAllowed('viewTransactionsCustomerManagement') && (
                        <a
                          href="#"
                          className={`tab-nav__item ${currentTab === 'transactions' ? 'active' : ''}`}
                          onClick={(e) => setTab(e, 'transactions')}
                          data-content="tab-transactions"
                        >
                          {isLoading ? <Skeleton height="20" width="100" /> : t('customerDetail.tabTransactions')}
                        </a>
                      )}
                       {isAllowed('viewTransactionsCustomerManagement') && (
                        <a
                          href="#"
                          className={`tab-nav__item ${currentTab === 'Faultytransactions' ? 'active' : ''}`}
                          onClick={(e) => setTab(e, 'Faultytransactions')}
                          data-content="tab-transactions"
                        >
                          {isLoading ? <Skeleton height="20" width="100" /> : t('customerDetail.faultyTransaction')}
                        </a>
                      )}
                      {isAllowed('viewChargeCoinTransactions') && (
                        <a
                          href="#"
                          className={`tab-nav__item ${currentTab === 'chargeCoinTransactions' ? 'active' : ''}`}
                          onClick={(e) => setTab(e, 'chargeCoinTransactions')}
                          data-content="tab-chargeCoinTransactions"
                        >
                          {isLoading ? <Skeleton height="20" width="100" /> : t('customerDetail.tabChargecoin')}
                        </a>
                      )}
                    </div>
                    {currentTab === 'profile' && (
                      <div className="tab-content-block">
                        <div className="tab-content__item active" id="charger-details-tab">
                          {currentTab === 'profile' && <CustomerProfile />}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Card>
              {currentTab === 'profile' && (
                <Card className="customerDetail-table">
                  <div className="customerDetail-table-heading">
                    {isLoader ? <Skeleton height="20" width="100" /> : t('customerDetail.vehicleDetails')}
                  </div>
                  <div className="customerDetail-list-table">
                    <div className="table-responsive">
                      <table className="record-list-table">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height="20" width="100" /> : t('customerDetail.srNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height="20" width="100" /> : t('customerDetail.vehicleName')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height="20" width="100" /> : t('customerDetail.vehicleRegistrationNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height="20" width="100" /> : t('customerDetail.sessions')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height="20" width="100" /> : t('customerDetail.evccId')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height="20" width="100" /> : t('AutoCharge')}</span>
                              </div>
                            </th>
                            {isAllowed('evccMap') && (
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height="20" width="100" /> : t('Action')}</span>
                                </div>
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {_.isEmpty(_.get(customerProfileList, 'vehicles')) ? (
                            <tr>
                              <td colSpan={8} className="border-0">
                                <div className="empty-data-block">{t('customerDetail.noData')}</div>
                              </td>
                            </tr>
                          ) : (
                            _.map(_.get(customerProfileList, 'vehicles'), (vehicle, index) => {
                              return (
                                <tr key={`vehicle-data-item-${index}`}>
                                  <td>{isLoader ? <Skeleton height="20" width="100" /> : index + 1}</td>
                                  <td>
                                    <span className="item-icon">
                                      {isLoader ? <Skeleton height="20" width="20" circle="true" /> : <AiOutlineCar />}
                                    </span>
                                    <span>
                                      {isLoader ? <Skeleton height="20" width="100" /> : _.get(vehicle, 'make', '')} {_.get(vehicle, 'model', '')}
                                    </span>
                                  </td>
                                  <td>{isLoader ? <Skeleton height="20" width="100" /> : _.get(vehicle, 'vin_num', '-')}</td>
                                  <td>{isLoader ? <Skeleton height="20" width="100" /> : _.get(vehicle, 'session_count', '-')}</td>
                                  <td>{isLoader ? <Skeleton height="20" width="100" /> : _.get(vehicle, 'evcc_id', '-')}</td>
                                  <td title="Enable/Disable AutoCharge">
                                    {isLoader ? (
                                      <Skeleton height="20" width="100" />
                                    ) : (
                                      <ToggleSwitch
                                        title="Enable/Disable AutoCharge"
                                        checked={_.get(vehicle, 'is_autocharge_enabled')}
                                        onChange={(e) => {
                                          const isCheck = e.target.checked;
                                          const id = _.get(vehicle, 'id');
                                          const customerData = _.omit(vehicle, ['is_default', 'year', 'unit_consumed', 'no_of_session']);
                                          if (isCheck) {
                                            autoChargeCustomer({ ...customerData, is_autocharge_enabled: true, id });
                                          } else {
                                            autoChargeCustomer({ ...customerData, is_autocharge_enabled: false, id });
                                          }
                                        }}
                                        disabled={isDeleted}
                                      />
                                    )}
                                  </td>
                                  {isAllowed('evccMap') && (
                                    <td>
                                      <div
                                        onClick={() => {
                                          setShowEditVehicleForm(true);
                                          setVehicleId(_.get(vehicle, 'id', ''));
                                        }}
                                      >
                                        <Button>{isLoader ? <Skeleton height={15} width={100} /> : t('customerDetail.evccButton')}</Button>
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Card>
              )}
              {currentTab === 'bookings' && <CustomerBooking />}
              {currentTab === 'transactions' && <CustomerTransaction />}
              {currentTab === 'chargeCoinTransactions' && <ChargeCoinTransaction />}
              {currentTab === 'Faultytransactions' && <CustomerFaultyTransaction />}
            </div>
          </div>
        </SkeletonTheme>
        {showWalletForm && (
          <Modal show={showWalletForm} size="lg" centered>
            <div className="wallet-transaction_main">
              <Card>
                <div className="wallet-transaction-header__block">
                  <div className="wallet-transaction-name">
                    <span>{t('customerDetail.walletTransaction')}</span>
                  </div>
                  <div className="close-btn">
                    <MdClose size={30} color="#be210b" onClick={handleClose} />
                  </div>
                </div>
                <div className="wallet-transaction_form">
                  <Formik
                    initialValues={{
                      amount: '',
                      user: _.get(customerProfileList, 'id', ''),
                      type: '',
                      message: '',
                    }}
                    validationSchema={WalletTransactionSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      walletTransaction(values);
                      setSubmitting(false);
                    }}
                  >
                    {({ values, errors, touched, setFieldValue, handleSubmit, handleChange, isSubmitting }) => (
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col lg={6}>
                            <TextInput
                              isRequired
                              label={t('customerDetail.walletAmount')}
                              type="number"
                              placeholder={t('customerDetail.walletAmount')}
                              name="amount"
                              value={_.get(values, 'amount')}
                              onChange={handleChange}
                              error={errors.amount && touched.amount ? errors.amount : null}
                            />
                          </Col>
                          <Col lg={6}>
                            <Select
                              isRequired
                              label={t('customerDetail.walletType')}
                              options={[
                                { label: 'Credit', value: 'credit' },
                                { label: 'Debit', value: 'debit' },
                              ]}
                              placeholder={t('placeHolder.selectType')}
                              name="type"
                              value={values.type}
                              onChange={(val) => setFieldValue(`type`, val)}
                              error={errors.type && touched.type ? errors.type : null}
                            />
                          </Col>
                          <Col lg={12}>
                            <TextInput
                              isRequired
                              as="textarea"
                              rows="4"
                              inputClass="scrollable"
                              placeholder={t('customerDetail.walletMessage')}
                              label={t('customerDetail.walletMessage')}
                              name="message"
                              value={values.message}
                              onChange={handleChange}
                              error={errors.message && touched.message ? errors.message : null}
                            />
                          </Col>
                        </Row>
                        <div className="wallet-transaction_button">
                          <Button className="wallet-transaction-btn cancel_btn" onClick={handleClose}>
                            {t('button.cancel')}
                          </Button>
                          <Button type="submit" disabled={isSubmitting} className="wallet-transaction-btn create-btn">
                            {t('button.submit')}
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Card>
            </div>
          </Modal>
        )}
        {showEditForm && (
          <Modal show={showEditForm} size="lg" centered>
            <div className="customer-form-page__main">
              <Card>
                <div className="customer-form-header__block">
                  <div className="customer-header-name">
                    <span>{t('customerDetail.editCustomer')} </span>
                  </div>
                  <div className="close___btn">
                    <MdClose size={30} color="#BE210B" onClick={handleClose} />
                  </div>
                </div>
                <div className="customer-form-body__block">
                  <div className="customer-form--block">
                    <Formik
                      initialValues={{
                        name: '',
                        first_name: _.get(customerProfileList, 'first_name'),
                        last_name: _.get(customerProfileList, 'last_name'),
                        phone: `+${_.get(customerProfileList, 'country_code')} ${_.get(customerProfileList, 'phone')}`.toString(),
                        email: _.get(customerProfileList, 'email'),
                      }}
                      // validationSchema={CustomerSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        const updateCustomerData = _.omit(values, ['phone']);
                        updateCustomer({
                          ...updateCustomerData,
                          name: values.first_name + ' ' + values.last_name,
                        });
                        setSubmitting(false);
                      }}
                    >
                      {({ values, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
                        <Form onSubmit={handleSubmit}>
                          <Row>
                            <Col xl={6} lg={12}>
                              <TextInput
                                // isRequired
                                label={t('customerDetail.editFirstName')}
                                placeholder={t('customerDetail.editFirstName')}
                                name="first_name"
                                value={values.first_name}
                                onChange={handleChange}
                                // error={errors.first_name && touched.first_name ? errors.first_name : null}
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <TextInput
                                // isRequired
                                label={t('customerDetail.editLastName')}
                                placeholder={t('customerDetail.editLastName')}
                                name="last_name"
                                value={values.last_name}
                                onChange={handleChange}
                                // error={errors.last_name && touched.last_name ? errors.last_name : null}
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <PhoneNumberInput
                                disabled
                                label={t('customerDetail.editPhoneNumber')}
                                name="phoneNumber"
                                value={values.phone}
                                onChange={(val) => {
                                  setFieldValue(`phone`, val);
                                }}
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <TextInput
                                label={t('customerDetail.editEmail')}
                                placeholder={t('customerDetail.editEmail')}
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                              />
                            </Col>
                          </Row>
                          <div className="form-btn__block">
                            <Button className="form_btn cancel____btn" onClick={handleClose}>
                              {t('button.cancel')}
                            </Button>
                            <Button type="submit" disabled={isSubmitting} className="form_btn">
                              {t('button.edit')}
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </Card>
            </div>
          </Modal>
        )}
        {showRoleForm && (
          <Modal show={showRoleForm} size="lg" centered>
            <div className="customer-form-page__main">
              <Card>
                <div className="customer-form-header__block">
                  <div className="customer-header-name">
                    <span>{t('customerDetail.changeRole')} </span>
                  </div>
                  <div className="close___btn">
                    <MdClose size={30} color="#BE210B" onClick={handleClose} />
                  </div>
                </div>
                <div className="customer-form-body__block">
                  <div className="customer-form--block">
                    <Formik
                      initialValues={{
                        username: _.get(customerProfileList, 'username', ''),
                        password: '',
                        role: _.get(customerProfileList, 'role', ''),
                      }}
                      validationSchema={CustomerSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        updateCustomer(values);
                        setSubmitting(false);
                      }}
                    >
                      {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
                        <Form onSubmit={handleSubmit}>
                          <Row>
                            <Col>
                              <Select
                                label={t('customerDetail.role')}
                                placeholder={t('customerDetail.role')}
                                className="customer_roles-select__box"
                                value={_.get(values, 'role', '')}
                                options={_.map(allRoles, (item) => {
                                  return { label: _.startCase(item.name), value: item.name };
                                })}
                                onMenuScrollDown={true}
                                getDataOnScrollDown={getAllRoles}
                                onChange={(val) => setFieldValue(`role`, val)}
                              />
                            </Col>
                            <Col>
                              <TextInput
                                isRequired
                                label={t('customerDetail.userName')}
                                placeholder={t('customerDetail.userName')}
                                name="username"
                                value={values.username}
                                onChange={handleChange}
                                error={errors.username && touched.username ? errors.username : null}
                              />
                            </Col>
                            <TextInput
                              // isRequired
                              label={t('customerDetail.password')}
                              type="password"
                              name="password"
                              value={values.password}
                              onChange={(e) => setFieldValue('password', e.target.value)}
                              // error={errors.password && touched.password ? errors.password : null}
                            />
                          </Row>
                          <div className="form-btn__block">
                            <Button className="form_btn cancel____btn" onClick={handleClose}>
                              {t('button.cancel')}
                            </Button>
                            <Button type="submit" disabled={isSubmitting} className="form_btn">
                              {t('button.changeRole')}
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </Card>
            </div>
          </Modal>
        )}
        {showEditVehicleForm && (
          <Modal show={showEditVehicleForm} size="lg" centered>
            <div className="wallet-transaction_main">
              <Card>
                <div className="wallet-transaction-header__block">
                  <div className="wallet-transaction-name">
                    <span>{t('customerDetail.evccUpdate')}</span>
                  </div>
                  <div className="close-btn">
                    <MdClose size={30} color="#be210b" onClick={handleClose} />
                  </div>
                </div>
                <div className="wallet-transaction_form">
                  <Formik
                    initialValues={
                      vehicleId
                        ? {
                            evcc_id: _.map(currentVehicle, (vehicle) => vehicle && vehicle.evcc_id).toString(),
                          }
                        : { evcc_id: '' }
                    }
                    validationSchema={VehicleSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      editVehicleDetail({
                        ...values,
                        id: vehicleId,
                      });
                      setSubmitting(false);
                    }}
                  >
                    {({ values, errors, touched, handleSubmit, handleChange, dirty }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="vehicle-form__inner">
                          <Row>
                            <Col lg={12} md={12}>
                              <TextInput
                                label={t('customerDetail.enterEvccId')}
                                placeholder={t('customerDetail.enterEvccId')}
                                name="evcc_id"
                                value={values.evcc_id}
                                onChange={handleChange}
                                error={errors.evcc_id && touched.evcc_id ? errors.evcc_id : null}
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className="vehicle-save--btn--block">
                          <Button type="submit" className="vehicle-save-btn save--btn" disabled={!dirty}>
                            {t('customerDetail.evccButton')}
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Card>
            </div>
          </Modal>
        )}
        {showModal && (
          <Modal show={showModal} centered>
            <RfidDetailsModal onClose={handleClose} />
          </Modal>
        )}
        {showBookingForm && (
          <Modal show={showBookingForm} size="xl" centered>
            <ScheduleBookingForm onClose={handleClose} />
          </Modal>
        )}
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </React.Fragment>
  );
};
export default CustomerDetail;
