import LayoutContainer from 'components/layout/LayoutContainer';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import AdminHeader from 'components/header';
import { Col, Row, Modal, Spinner } from 'react-bootstrap';
// import { AiOutlineCar } from 'react-icons/ai';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
// import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';
// import DropDown from 'components/inputs/DropDown';
import { FETCH_VEHICLE, DOWNLOAD_EXCEL_VEHICLE } from 'actions/vehicle';
import { useSelector, useDispatch } from 'react-redux';
import SearchBox from 'components/general/SearchBox';
import { DELETE_VEHICLE } from 'actions/vehicle';
import PropTypes from 'prop-types';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE, GET_VEHICLES, MANAGE_VEHICLES } from 'components/common/constant';
import { BiSort } from 'react-icons/bi';
import { BsDownload } from 'react-icons/bs';
import fileDownload from 'js-file-download';
import { useTranslation } from 'react-i18next';
import { AiOutlineCar } from 'react-icons/ai';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const VehicleList = () => {
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);
  const [searchText, setSearchText] = useState('');
  const [currentTab, setCurrentTab] = useState('totalVehicles');
  // const [allVehiclesData, setAllVehiclesData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [vehicleId, setVehicleId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const allVehicles = useSelector((state) => state.vehicle.vehicles);
  const isLoader = useSelector((state) => state.vehicle.isLoading);
  const page = useSelector((state) => state.vehicle.page);
  const page_no = useSelector((state) => state.vehicle.page_no);
  const totalData = useSelector((state) => state.vehicle.total);
  const limit = useSelector((state) => state.vehicle.limit);
  const totalPages = useSelector((state) => state.vehicle.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const getVehicle = useCallback((data) => {
    if (searchText) {
      const vehicleData = {
        ...data,
        search: searchText,
        ownership_type: 'b2b',
      };
      dispatch({ type: FETCH_VEHICLE, payload: vehicleData });
    } else {
      dispatch({ type: FETCH_VEHICLE, payload: data ? { ...data, ownership_type: 'b2b' } : { ownership_type: 'b2b' } });
    }
  }, []);

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setVehicleId('');
  };

  const deleteVehicle = useCallback(
    () =>
      dispatch({
        type: DELETE_VEHICLE,
        payload: vehicleId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getVehicle({ page: 1 });
          }
        },
      }),
    [vehicleId]
  );

  const downloadExcelVehicle = useCallback(() => {
    const data = {
      excel: true,
      ownership_type: 'b2b',
    };
    dispatch({
      type: DOWNLOAD_EXCEL_VEHICLE,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            fileDownload(res.data, `${'Vehicle Data'}.xlsx`);
          }
        }
      },
    });
  }, []);

  const downloadFile = () => {
    downloadExcelVehicle();
  };

  // useEffect(() => {
  //   setAllVehiclesData(
  //     _.filter(
  //       allVehicles,
  //       (item) =>
  //         startsWith(_.get(item, 'make', ''), searchText) ||
  //         startsWith(_.get(item, 'model', ''), searchText) ||
  //         startsWith(_.get(item, 'RFID', ''), searchText) ||
  //         startsWith(moment(_.get(item, 'insurance_expiry')).format('ddd, ll'), searchText)
  //     )
  //   );
  // }, [searchText, allVehicles]);

  useEffect(() => {
    if (page_no) {
      getVehicle({ page });
    } else {
      getVehicle();
    }
  }, []);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (searchText) {
        const data = {
          search: searchText,
          page: page.selected + 1,
        };
        getVehicle(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getVehicle(data);
      }
    },
    [sortByItem, searchText]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getVehicle(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getVehicle(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const getVehiclePermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_VEHICLES),
      (data) => data.name
    );

  const manageVehiclePermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_VEHICLES),
      (data) => data.name
    );

  const handleSearch = () => {
    if (searchText.trim() === '') {
      // If search text is empty, load all data by passing an empty object
      getVehicle({});
    } else {
      // If search text is not empty, call the API with the current searchText
      getVehicle({ search: searchText, page: 1 });
    }
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('header.vehicleManagement')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="vehicle-management-page_main">
              <div className="vehicle-management-card-block"></div>
              <div className="vehicle-list-main-block">
                <Card>
                  {currentTab === 'totalVehicles' && (
                    <div className="vehicle-list-block">
                      <div className="vehicle-search-box">
                        <Row>
                          <Col xl={2}>
                            <div className={`vehicle-card-block `} onClick={() => setCurrentTab('totalVehicles')}>
                              {/* <VehicleCard
                              key="total-vehicles"
                              icon={<AiOutlineCar />}
                              title={totalData}
                              subTitle={t('addVehicleList.totalVehicles')}
                            /> */}

                              {
                                <div className="Vehicle-model-icon">
                                  {isLoader ? (
                                    <Skeleton circle="true" height={24} width={24} />
                                  ) : (
                                    <AiOutlineCar className="Vehicle-model-icon-photo" />
                                  )}
                                  {isLoader ? <Skeleton height={15} width={100} /> : totalData}
                                </div>
                              }
                            </div>
                          </Col>

                          <Col xxl={7} xl={!_.isEmpty(manageVehiclePermissions) ? 5 : 5}>
                            <SearchBox
                              preIcon={<BsSearch />}
                              value={searchText}
                              onChange={(e) => setSearchText(e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  handleSearch();
                                }
                              }}
                            />
                          </Col>

                          <Col xl={'auto'} md={2}>
                            <div className="report-search-box" onClick={handleSearch}>
                              <Button className="add-vehicle-btn">{t('button.search')}</Button>
                            </div>
                          </Col>

                          <Col xl={'auto'} md={'auto'}>
                            {(!_.isEmpty(manageVehiclePermissions) ||
                              (!_.isEmpty(getVehiclePermissions) && !_.isEmpty(manageVehiclePermissions))) && (
                              <Button className="add-vehicle-btn" onClick={() => navigateTo('/addVehicle')}>
                                {t('button.addVehicle')}
                              </Button>
                            )}
                          </Col>
                          <Col xl={'auto'} md={'auto'}>
                            {isLoading ? (
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                              </div>
                            ) : (
                              <div className="report-excel-icon icon-pos">
                                <BsDownload title="Download" size={28} color={'#3c7cdd'} onClick={downloadFile} id="dwnbtn" />
                              </div>
                            )}
                          </Col>
                        </Row>
                      </div>
                      <div className="vehicle-list-table">
                        <div className="table-responsive">
                          <table className="record-list-table">
                            <thead>
                              <tr>
                                <th>
                                  <div className="sorting">
                                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addVehicleList.srNo')}</span>
                                  </div>
                                </th>
                                <th>
                                  <div className="sorting">
                                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addVehicleList.evType')}</span>
                                    <span className="ico" onClick={() => handleSorting('type', sortByItem)}>
                                      <BiSort size={15} />
                                    </span>
                                  </div>
                                </th>
                                <th>
                                  <div className="sorting">
                                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addVehicleList.make')}</span>
                                    <span className="ico" onClick={() => handleSorting('make', sortByItem)}>
                                      <BiSort size={15} />
                                    </span>
                                  </div>
                                </th>
                                <th>
                                  <div className="sorting">
                                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addVehicleList.vehicleRegistrationNo')}</span>
                                    <span className="ico" onClick={() => handleSorting('rto_no', sortByItem)}>
                                      <BiSort size={15} />
                                    </span>
                                  </div>
                                </th>
                                <th>
                                  <div className="sorting">
                                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addVehicleList.unitConsumption')}</span>
                                    <span className="ico" onClick={() => handleSorting('rto_no', sortByItem)}>
                                      <BiSort size={15} />
                                    </span>
                                  </div>
                                </th>
                                <th>
                                  <div className="sorting">
                                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addVehicleList.rfid/IDTag')}</span>
                                    <span className="ico" onClick={() => handleSorting('RFID', sortByItem)}>
                                      <BiSort size={15} />
                                    </span>
                                  </div>
                                </th>
                                {(!_.isEmpty(manageVehiclePermissions) ||
                                  (!_.isEmpty(getVehiclePermissions) && !_.isEmpty(manageVehiclePermissions))) && (
                                  <th>
                                    <div>
                                      <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addVehicleList.action')}</span>
                                    </div>
                                  </th>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {isLoader ? (
                                <tr>
                                  <td colSpan={10} className="border-0">
                                    <Skeleton height={15} />
                                  </td>
                                </tr>
                              ) : _.isEmpty(allVehicles) ? (
                                <tr>
                                  <td colSpan={8} className="border-0">
                                    <div className="empty-data-block">{t('addVehicleList.noData')}</div>
                                  </td>
                                </tr>
                              ) : (
                                !(isLoader || _.isUndefined(isLoader)) &&
                                _.map(allVehicles, (vehicle, index) => {
                                  const serial_num = limit * (page - 1) + index;
                                  return (
                                    <tr key={`vehicle-data-item-${serial_num}`}>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(vehicle, 'type', '')}</td>
                                      <td>
                                        {isLoader ? (
                                          <Skeleton height={15} width={100} />
                                        ) : (
                                          <>
                                            {_.get(vehicle, 'make', '')} ({_.get(vehicle, 'model', '')})
                                          </>
                                        )}
                                      </td>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(vehicle, 'rto_no', '')}</td>
                                      <td>
                                        {isLoader ? (
                                          <Skeleton height={15} width={100} />
                                        ) : (
                                          Math.round(_.get(vehicle, 'unit_consumed', '') * 10 ** 2) / 10 ** 2
                                        )}
                                      </td>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(vehicle, 'RFID', '')}</td>
                                      {(!_.isEmpty(manageVehiclePermissions) ||
                                        (!_.isEmpty(getVehiclePermissions) && !_.isEmpty(manageVehiclePermissions))) && (
                                        <td>
                                          <div key={`action-${index}`} className="vehicle-list">
                                            <span className="list-icon">
                                              <div className={'more-info'}>
                                                <span className="chargerStateList-table-editIcon">
                                                  {isLoader ? (
                                                    <Skeleton circle="true" height={24} width={24} />
                                                  ) : (
                                                    <FiEdit2 title="Edit" onClick={() => navigateTo(`/editVehicle/${vehicle.id}`)} />
                                                  )}
                                                </span>
                                                <span className="chargerStateList-table-deleteIcon">
                                                  {isLoader ? (
                                                    <Skeleton circle="true" height={24} width={24} />
                                                  ) : (
                                                    <RiDeleteBinLine
                                                      title="Delete"
                                                      onClick={() => {
                                                        setShowDeleteModal(true);
                                                        setVehicleId(_.get(vehicle, 'id', ''));
                                                      }}
                                                    />
                                                  )}
                                                </span>
                                              </div>
                                            </span>
                                          </div>
                                        </td>
                                      )}
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allVehicles) && (
                        // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                        <ReactPagination
                          currentPage={page}
                          limit={limit}
                          total={totalData}
                          handlePageClick={(pageVal) => handlePageClick(pageVal)}
                          totalPages={totalPages}
                          marginPagesDisplayed={1}
                        />
                      )}
                      {showDeleteModal && (
                        <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
                          <DeleteModal title="vehicle" onClose={handleCloseDeleteModel} onRemove={deleteVehicle} />
                        </Modal>
                      )}
                    </div>
                  )}
                  {currentTab === 'chargingVehicles' && (
                    <div className="d-flex align-items-center justify-content-center">{t('placeHolder.pending')}</div>
                  )}
                  {currentTab === 'finishedCharging' && (
                    <div className="d-flex align-items-center justify-content-center">{t('placeHolder.pending')}</div>
                  )}
                  {currentTab === 'vehiclesProblems' && (
                    <div className="d-flex align-items-center justify-content-center">{t('placeHolder.pending')}</div>
                  )}
                </Card>
              </div>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </React.Fragment>
  );
};

export default VehicleList;

export const VehicleCard = (props) => {
  const { icon, title, subTitle, metaIcon } = props;

  return (
    <Card className="vehicle-card">
      <div className="vehicle-detail-box">
        <div className="vehicle-icon">
          <div className="car-icon">{icon}</div>
          <div className="vehicle-status-icon">{metaIcon}</div>
        </div>
        <div className="vehicle__count-info">
          <div className="total-vehicle">{title}</div>
          <div className="info-text">{subTitle}</div>
        </div>
      </div>
    </Card>
  );
};

VehicleCard.propTypes = {
  /**
   * Card icon
   */
  icon: PropTypes.node,
  /**meta icon */
  metaIcon: PropTypes.node,
  /**
   * Vehicle title
   */
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Vehical sub-title
   */
  subTitle: PropTypes.string,
};
