import {
  STATION_UNIT_CONSUMPTION,
  STATION_UNIT_CONSUMPTION_SUCCESS,
  STATION_UNIT_CONSUMPTION_FAIL,
  B2B_STATION_CHARGING_HISTORY,
  B2B_STATION_CHARGING_HISTORY_SUCCESS,
  B2B_STATION_CHARGING_HISTORY_FAIL,
  B2C_STATION_CHARGING_HISTORY_SUCCESS,
  B2C_STATION_CHARGING_HISTORY_FAIL,
  B2C_STATION_CHARGING_HISTORY,
  JSON_REQUEST_RESPONSE_REPORT_SUCCESS,
  JSON_REQUEST_RESPONSE_REPORT_FAIL,
  JSON_REQUEST_RESPONSE_REPORT,
  WALLET_TRANSACTION_REPORT_SUCCESS,
  WALLET_TRANSACTION_REPORT_FAIL,
  WALLET_TRANSACTION_REPORT,
  ALL_DATA_REPORT_SUCCESS,
  ALL_DATA_REPORT_FAIL,
  ALL_DATA_REPORT,
  DOWNLOAD_ALL_DATA_REPORT_SUCCESS,
  DOWNLOAD_ALL_DATA_REPORT_FAIL,
  DOWNLOAD_ALL_DATA_REPORT,
  OCPI_BOOKING,
  OCPI_BOOKING_SUCCESS,
  OCPI_BOOKING_FAIL,
  DOWNLOAD_OCPI_BOOKING,
  DOWNLOAD_OCPI_BOOKING_SUCCESS,
  DOWNLOAD_OCPI_BOOKING_FAIL,
  MOST_VEHICLE_CHARGED_LIST,
  MOST_VEHICLE_CHARGED_LIST_SUCCESS,
  MOST_VEHICLE_CHARGED_LIST_FAIL,
  APP_USER_DETAILS_LIST_SUCCESS,
  APP_USER_DETAILS_LIST_FAIL,
  APP_USER_DETAILS_LIST,
  STATION_CHARGING_SUMMARY_SUCCESS,
  STATION_CHARGING_SUMMARY_FAIL,
  STATION_CHARGING_SUMMARY,
  PRIVATE_STATION_CHARGING_SUMMARY_SUCCESS,
  PRIVATE_STATION_CHARGING_SUMMARY_FAIL,
  PRIVATE_STATION_CHARGING_SUMMARY,
  PUBLIC_STATION_CHARGING_SUMMARY_SUCCESS,
  PUBLIC_STATION_CHARGING_SUMMARY_FAIL,
  PUBLIC_STATION_CHARGING_SUMMARY,
  BOOKING_HISTORY_SUCCESS,
  BOOKING_HISTORY_FAIL,
  BOOKING_HISTORY,
  USER_CHARGING_HISTORY_SUCCESS,
  USER_CHARGING_HISTORY_FAIL,
  USER_CHARGING_HISTORY,
  BOOKING_SUMMARY_REPORT_SUCCESS,
  BOOKING_SUMMARY_REPORT_FAIL,
  BOOKING_SUMMARY_REPORT,
  MONEY_TRANSACTION_PG_REPORT_SUCCESS,
  MONEY_TRANSACTION_PG_REPORT_FAIL,
  MONEY_TRANSACTION_PG_REPORT,
  DOWNLOAD_STATION_CHARGING_SUMMARY_SUCCESS,
  DOWNLOAD_STATION_CHARGING_SUMMARY_FAIL,
  DOWNLOAD_STATION_CHARGING_SUMMARY,
  DOWNLOAD_PRIVATE_STATION_CHARGING_SUMMARY_SUCCESS,
  DOWNLOAD_PRIVATE_STATION_CHARGING_SUMMARY_FAIL,
  DOWNLOAD_PRIVATE_STATION_CHARGING_SUMMARY,
  DOWNLOAD_PUBLIC_STATION_CHARGING_SUMMARY_SUCCESS,
  DOWNLOAD_PUBLIC_STATION_CHARGING_SUMMARY_FAIL,
  DOWNLOAD_PUBLIC_STATION_CHARGING_SUMMARY,
  DOWNLOAD_METER_VALUES_SUCCESS,
  DOWNLOAD_METER_VALUES_FAIL,
  DOWNLOAD_METER_VALUES,
  DOWNLOAD_BOOKING_HISTORY_SUCCESS,
  DOWNLOAD_BOOKING_HISTORY_FAIL,
  DOWNLOAD_BOOKING_HISTORY,
  DOWNLOAD_USER_CHARGING_HISTORY_SUCCESS,
  DOWNLOAD_USER_CHARGING_HISTORY_FAIL,
  DOWNLOAD_USER_CHARGING_HISTORY,
  DOWNLOAD_B2C_STATION_CHARGING_HISTORY,
  DOWNLOAD_B2C_STATION_CHARGING_HISTORY_SUCCESS,
  DOWNLOAD_B2C_STATION_CHARGING_HISTORY_FAIL,
  DOWNLOAD_MONEY_TRANSACTION_PG_REPORT_SUCCESS,
  DOWNLOAD_MONEY_TRANSACTION_PG_REPORT_FAIL,
  DOWNLOAD_WALLET_TRANSACTION_REPORT_SUCCESS,
  DOWNLOAD_WALLET_TRANSACTION_REPORT_FAIL,
  DOWNLOAD_MONEY_TRANSACTION_PG_REPORT,
  DOWNLOAD_WALLET_TRANSACTION_REPORT,
  DOWNLOAD_STATION_UNIT_CONSUMPTION,
  DOWNLOAD_STATION_UNIT_CONSUMPTION_SUCCESS,
  DOWNLOAD_STATION_UNIT_CONSUMPTION_FAIL,
  DOWNLOAD_CHARGING_HISTORY_FAIL,
  DOWNLOAD_CHARGING_HISTORY_SUCCESS,
  DOWNLOAD_CHARGING_HISTORY,
  CALENDAR_VIEW_SUCCESS,
  CALENDAR_VIEW_FAIL,
  CALENDAR_VIEW,
  DOWNLOAD_STATION_DOWNTIME_REPORT,
  DOWNLOAD_STATION_DOWNTIME_REPORT_SUCCESS,
  DOWNLOAD_STATION_DOWNTIME_REPORT_FAIL,
  STATION_DOWNTIME_REPORT,
  STATION_DOWNTIME_REPORT_SUCCESS,
  STATION_DOWNTIME_REPORT_FAIL,
  DOWNLOAD_B2B_STATION_CHARGING_HISTORY_FAIL,
  DOWNLOAD_B2B_STATION_CHARGING_HISTORY_SUCCESS,
  DOWNLOAD_B2B_STATION_CHARGING_HISTORY,
  AC_METER_READING_REPORT,
  AC_METER_READING_REPORT_SUCCESS,
  AC_METER_READING_REPORT_FAIL,
  DOWNLOAD_AC_METER_READING_REPORT,
  DOWNLOAD_AC_METER_READING_REPORT_SUCCESS,
  DOWNLOAD_AC_METER_READING_REPORT_FAIL,
  DOWNLOAD_APP_USER_DETAIL_REPORT,
  DOWNLOAD_APP_USER_DETAIL_REPORT_FAIL,
  DOWNLOAD_APP_USER_DETAIL_REPORT_SUCCESS,
  DOWNLOAD_JOB_VOUCHER,
  DOWNLOAD_JOB_VOUCHER_SUCCESS,
  DOWNLOAD_JOB_VOUCHER_FAIL,
  DOWNLOAD_GST_VOUCHER,
  DOWNLOAD_GST_VOUCHER_SUCCESS,
  DOWNLOAD_GST_VOUCHER_FAIL,
  INVOICE_REPORT,
  INVOICE_REPORT_SUCCESS,
  INVOICE_REPORT_FAIL,
  DOWNLOAD_INVOICE_REPORT,
  DOWNLOAD_INVOICE_REPORT_SUCCESS,
  DOWNLOAD_INVOICE_REPORT_FAIL,
  DOWNLOAD_BOOKING_SUMMARY_REPORT,
  DOWNLOAD_BOOKING_SUMMARY_REPORT_SUCCESS,
  DOWNLOAD_BOOKING_SUMMARY_REPORT_FAIL,
  DOWNLOAD_MOST_VEHICLE_CHARGED_LIST,
  DOWNLOAD_MOST_VEHICLE_CHARGED_LIST_SUCCESS,
  DOWNLOAD_MOST_VEHICLE_CHARGED_LIST_FAIL,
  PAYMENT_STATUS_REPORT,
  PAYMENT_STATUS_REPORT_SUCCESS,
  PAYMENT_STATUS_REPORT_FAIL,
  DOWNLOAD_PAYMENT_STATUS_REPORT,
  DOWNLOAD_PAYMENT_STATUS_REPORT_SUCCESS,
  DOWNLOAD_PAYMENT_STATUS_REPORT_FAIL,
  VEHICLE_CHARGING_SESSION,
  VEHICLE_CHARGING_SESSION_SUCCESS,
  VEHICLE_CHARGING_SESSION_FAIL,
  DOWNLOAD_VEHICLE_CHARGING_SESSION,
  DOWNLOAD_VEHICLE_CHARGING_SESSION_SUCCESS,
  DOWNLOAD_VEHICLE_CHARGING_SESSION_FAIL,
  FAULTY_BOOKING_REPORT,
  FAULTY_BOOKING_REPORT_SUCCESS,
  FAULTY_BOOKING_REPORT_FAIL,
  DOWNLOAD_FAULTY_BOOKING_REPORT,
  DOWNLOAD_FAULTY_BOOKING_REPORT_SUCCESS,
  DOWNLOAD_FAULTY_BOOKING_REPORT_FAIL,
  CHARGING_SESSION_REPORT,
  CHARGING_SESSION_REPORT_SUCCESS,
  CHARGING_SESSION_REPORT_FAIL,
  DOWNLOAD_CHARGING_SESSION_REPORT,
  DOWNLOAD_CHARGING_SESSION_REPORT_SUCCESS,
  DOWNLOAD_CHARGING_SESSION_REPORT_FAIL,
  PLANT_LOAD_FACTOR_REPORT,
  PLANT_LOAD_FACTOR_REPORT_SUCCESS,
  PLANT_LOAD_FACTOR_REPORT_FAIL,
  DOWNLOAD_PLANT_LOAD_FACTOR_REPORT,
  DOWNLOAD_PLANT_LOAD_FACTOR_REPORT_SUCCESS,
  DOWNLOAD_PLANT_LOAD_FACTOR_REPORT_FAIL,
  HMIL_CUSTOM_CHARGING_REPORT,
  HMIL_CUSTOM_CHARGING_REPORT_SUCCESS,
  HMIL_CUSTOM_CHARGING_REPORT_FAIL,
  DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT_SUCCESS,
  DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT_FAIL,
  DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT,
  CUSTOMER_WALLET_TRANSACTION_REPORT,
  CUSTOMER_WALLET_TRANSACTION_REPORT_SUCCESS,
  CUSTOMER_WALLET_TRANSACTION_REPORT_FAIL,
  DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT,
  DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT_SUCCESS,
  DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT_FAIL,
  PARTNER_CHARGING_SUMMARY,
  PARTNER_CHARGING_SUMMARY_SUCCESS,
  PARTNER_CHARGING_SUMMARY_FAIL,
  DOWNLOAD_PARTNER_CHARGING_SUMMARY,
  DOWNLOAD_PARTNER_CHARGING_SUMMARY_SUCCESS,
  DOWNLOAD_PARTNER_CHARGING_SUMMARY_FAIL,
} from 'actions/dataReport';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* stationUnitConsumption(action) {
  try {
    const response = yield call(API.stationUnitConsumption, action.payload);
    yield put({ type: STATION_UNIT_CONSUMPTION_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: STATION_UNIT_CONSUMPTION_FAIL, payload: { error: e } });
  }
}

function* ocpiPartnerChargingReport(action) {
  try {
    const response = yield call(API.ocpiPartnerChargingReport, action.payload);
    yield put({ type: OCPI_BOOKING_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: OCPI_BOOKING_FAIL, payload: { error: e } });
  }
}

function* downloadocpiPartnerChargingReport(action) {
  try {
    const response = yield call(API.downloadocpiPartnerChargingReport, action.payload);
    yield put({ type: DOWNLOAD_OCPI_BOOKING_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_OCPI_BOOKING_FAIL, payload: { error: e } });
  }
}

function* downloadStationUnitConsumption(action) {
  try {
    const response = yield call(API.downloadStationUnitConsumption, action.payload);
    yield put({ type: DOWNLOAD_STATION_UNIT_CONSUMPTION_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_STATION_UNIT_CONSUMPTION_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* b2bStationChargingHistory(action) {
  try {
    const response = yield call(API.b2bStationChargingHistory, action.payload);
    yield put({ type: B2B_STATION_CHARGING_HISTORY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: B2B_STATION_CHARGING_HISTORY_FAIL, payload: { error: e } });
  }
}

function* downloadB2BStationChargingHistory(action) {
  try {
    const response = yield call(API.downloadB2BStationChargingHistory, action.payload);
    yield put({ type: DOWNLOAD_B2B_STATION_CHARGING_HISTORY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_B2B_STATION_CHARGING_HISTORY_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* downloadChargingHistory(action) {
  try {
    const response = yield call(API.downloadChargingHistory, action.payload);
    yield put({ type: DOWNLOAD_CHARGING_HISTORY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_CHARGING_HISTORY_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}
function* b2cStationChargingHistory(action) {
  try {
    const response = yield call(API.b2cStationChargingHistory, action.payload);
    yield put({ type: B2C_STATION_CHARGING_HISTORY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: B2C_STATION_CHARGING_HISTORY_FAIL, payload: { error: e } });
  }
}

function* downloadB2CStationChargingHistory(action) {
  try {
    const response = yield call(API.downloadB2CStationChargingHistory, action.payload);
    yield put({ type: DOWNLOAD_B2C_STATION_CHARGING_HISTORY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_B2C_STATION_CHARGING_HISTORY_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* jsonRequestResponseReport(action) {
  try {
    const response = yield call(API.jsonRequestResponseReport, action.payload);
    yield put({ type: JSON_REQUEST_RESPONSE_REPORT_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: JSON_REQUEST_RESPONSE_REPORT_FAIL, payload: { error: e } });
  }
}

function* walletTransactionReport(action) {
  try {
    const response = yield call(API.walletTransactionReport, action.payload);
    yield put({ type: WALLET_TRANSACTION_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: WALLET_TRANSACTION_REPORT_FAIL, payload: { error: e } });
  }
}

function* alldatareport(action) {
  try {
    const response = yield call(API.alldatareport, action.payload);
    yield put({ type: ALL_DATA_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: ALL_DATA_REPORT_FAIL, payload: { error: e } });
  }
}

function* HMILCustomChargingReport(action) {
  try {
    const response = yield call(API.HMILCustomChargingReport, action.payload);
    yield put({ type: HMIL_CUSTOM_CHARGING_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: HMIL_CUSTOM_CHARGING_REPORT_FAIL, payload: { error: e } });
  }
}

function* DownloadHMILCustomChargingReport(action) {
  try {
    const response = yield call(API.downloadHMILCustomChargingReport, action.payload);
    yield put({ type: DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT_FAIL, payload: { error: e } });
  }
}

function* downloadWalletTransactionReport(action) {
  try {
    const response = yield call(API.downloadWalletTransactionReport, action.payload);
    yield put({ type: DOWNLOAD_WALLET_TRANSACTION_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_WALLET_TRANSACTION_REPORT_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* downloadPlantLoadFactorReport(action) {
  try {
    const response = yield call(API.downloadPlantLoadFactorReport, action.payload);
    yield put({ type: DOWNLOAD_PLANT_LOAD_FACTOR_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_PLANT_LOAD_FACTOR_REPORT_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* downloadalldatareport(action) {
  try {
    const response = yield call(API.downloadalldatareport, action.payload);
    yield put({ type: DOWNLOAD_ALL_DATA_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_ALL_DATA_REPORT_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* appUserDetailsList(action) {
  try {
    const response = yield call(API.appUserDetailsList, action.payload);
    yield put({ type: APP_USER_DETAILS_LIST_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: APP_USER_DETAILS_LIST_FAIL, payload: { error: e } });
  }
}

function* downloadappUserDetailList(action) {
  try {
    const response = yield call(API.downloadappUserDetailList, action.payload);
    yield put({ type: DOWNLOAD_APP_USER_DETAIL_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_APP_USER_DETAIL_REPORT_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* bookingHistory(action) {
  try {
    const response = yield call(API.bookingHistory, action.payload);
    yield put({ type: BOOKING_HISTORY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: BOOKING_HISTORY_FAIL, payload: { error: e } });
  }
}

function* downloadBookingHistory(action) {
  try {
    const response = yield call(API.downloadBookingHistory, action.payload);
    yield put({ type: DOWNLOAD_BOOKING_HISTORY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_BOOKING_HISTORY_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* mostVehicleChargedList(action) {
  try {
    const response = yield call(API.mostVehicleChargedList, action.payload);
    yield put({ type: MOST_VEHICLE_CHARGED_LIST_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: MOST_VEHICLE_CHARGED_LIST_FAIL, payload: { error: e } });
  }
}

function* userChargingHistory(action) {
  try {
    const response = yield call(API.userChargingHistory, action.payload);
    yield put({ type: USER_CHARGING_HISTORY_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: USER_CHARGING_HISTORY_FAIL, payload: { error: e } });
  }
}

function* downloadUserChargingHistory(action) {
  try {
    const response = yield call(API.downloadUserChargingHistory, action.payload);
    yield put({ type: DOWNLOAD_USER_CHARGING_HISTORY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_USER_CHARGING_HISTORY_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* stationChargingSummary(action) {
  try {
    const response = yield call(API.stationChargingSummary, action.payload);
    yield put({ type: STATION_CHARGING_SUMMARY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: STATION_CHARGING_SUMMARY_FAIL, payload: { error: e } });
  }
}

function* downloadStationChargingSummary(action) {
  try {
    const response = yield call(API.downloadStationChargingSummary, action.payload);
    yield put({ type: DOWNLOAD_STATION_CHARGING_SUMMARY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_STATION_CHARGING_SUMMARY_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* privateStationChargingSummary(action) {
  try {
    const response = yield call(API.privateStationChargingSummary, action.payload);
    yield put({ type: PRIVATE_STATION_CHARGING_SUMMARY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: PRIVATE_STATION_CHARGING_SUMMARY_FAIL, payload: { error: e } });
  }
}

function* downloadPrivateStationChargingSummary(action) {
  try {
    const response = yield call(API.downloadPrivateStationChargingSummary, action.payload);
    yield put({ type: DOWNLOAD_PRIVATE_STATION_CHARGING_SUMMARY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_PRIVATE_STATION_CHARGING_SUMMARY_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* publicStationChargingSummary(action) {
  try {
    const response = yield call(API.publicStationChargingSummary, action.payload);
    yield put({ type: PUBLIC_STATION_CHARGING_SUMMARY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: PUBLIC_STATION_CHARGING_SUMMARY_FAIL, payload: { error: e } });
  }
}

function* downloadPublicStationChargingSummary(action) {
  try {
    const response = yield call(API.downloadPublicStationChargingSummary, action.payload);
    yield put({ type: DOWNLOAD_PUBLIC_STATION_CHARGING_SUMMARY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_PUBLIC_STATION_CHARGING_SUMMARY_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* downloadMeterValues(action) {
  try {
    const response = yield call(API.downloadMeterValues, action.payload);
    yield put({ type: DOWNLOAD_METER_VALUES_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_METER_VALUES_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* bookingSummaryReport(action) {
  try {
    const response = yield call(API.bookingSummaryReport, action.payload);
    yield put({ type: BOOKING_SUMMARY_REPORT_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: BOOKING_SUMMARY_REPORT_FAIL, payload: { error: e } });
  }
}

function* moneyTransactionPgReport(action) {
  try {
    const response = yield call(API.moneyTransactionPgReport, action.payload);
    yield put({ type: MONEY_TRANSACTION_PG_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: MONEY_TRANSACTION_PG_REPORT_FAIL, payload: { error: e } });
  }
}

function* downloadMoneyTransactionPGReport(action) {
  try {
    const response = yield call(API.downloadMoneyTransactionPGReport, action.payload);
    yield put({ type: DOWNLOAD_MONEY_TRANSACTION_PG_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_MONEY_TRANSACTION_PG_REPORT_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* calendarView(action) {
  try {
    const response = yield call(API.calendarView, action.payload);
    yield put({ type: CALENDAR_VIEW_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: CALENDAR_VIEW_FAIL, payload: { error: e } });
  }
}

function* stationDownTimeReport(action) {
  try {
    const response = yield call(API.stationDownTimeReport, action.payload);
    yield put({ type: STATION_DOWNTIME_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: STATION_DOWNTIME_REPORT_FAIL, payload: { error: e } });
  }
}

function* downloadStationDownTimeReport(action) {
  try {
    const response = yield call(API.downloadStationDownTimeReport, action.payload);
    yield put({ type: DOWNLOAD_STATION_DOWNTIME_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_STATION_DOWNTIME_REPORT_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* acMeterReadingReport(action) {
  try {
    const response = yield call(API.acMeterReadingReport, action.payload);
    yield put({ type: AC_METER_READING_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: AC_METER_READING_REPORT_FAIL, payload: { error: e } });
  }
}

function* downloadACMeterReadingReport(action) {
  try {
    const response = yield call(API.downloadACMeterReadingReport, action.payload);
    yield put({ type: DOWNLOAD_AC_METER_READING_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_AC_METER_READING_REPORT_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* downloadJobVoucherReport(action) {
  try {
    const response = yield call(API.downloadJobVoucherInvoice, action.payload);
    yield put({ type: DOWNLOAD_JOB_VOUCHER_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_JOB_VOUCHER_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* downloadGstVoucherReport(action) {
  try {
    const response = yield call(API.downloadGSTReport, action.payload);
    yield put({ type: DOWNLOAD_GST_VOUCHER_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_GST_VOUCHER_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* invoiceReport(action) {
  try {
    const response = yield call(API.invoiceReport, action.payload);
    yield put({ type: INVOICE_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: INVOICE_REPORT_FAIL, payload: { error: e } });
  }
}

function* downloadInvoiceReport(action) {
  try {
    const response = yield call(API.downloadInvoiceReport, action.payload);
    yield put({ type: DOWNLOAD_INVOICE_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_INVOICE_REPORT_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* downloadBookingSummaryReport(action) {
  try {
    const response = yield call(API.downloadBookingSummaryReport, action.payload);
    yield put({ type: DOWNLOAD_BOOKING_SUMMARY_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_BOOKING_SUMMARY_REPORT_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* downloadMostVehicleChargedList(action) {
  try {
    const response = yield call(API.downloadMostVehicleChargedList, action.payload);
    yield put({ type: DOWNLOAD_MOST_VEHICLE_CHARGED_LIST_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_MOST_VEHICLE_CHARGED_LIST_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* paymentStatusReport(action) {
  try {
    const response = yield call(API.paymentStatusReport, action.payload);
    yield put({ type: PAYMENT_STATUS_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: PAYMENT_STATUS_REPORT_FAIL, payload: { error: e } });
  }
}

function* downloadPaymentStatusReport(action) {
  try {
    const response = yield call(API.downloadPaymentStatusReport, action.payload);
    yield put({ type: DOWNLOAD_PAYMENT_STATUS_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_PAYMENT_STATUS_REPORT_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* vehicleChargingSession(action) {
  try {
    const response = yield call(API.vehicleChargingSession, action.payload);
    yield put({ type: VEHICLE_CHARGING_SESSION_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: VEHICLE_CHARGING_SESSION_FAIL, payload: { error: e } });
  }
}

function* downloadVehicleChargingSession(action) {
  try {
    const response = yield call(API.downloadVehicleChargingSession, action.payload);
    yield put({ type: DOWNLOAD_VEHICLE_CHARGING_SESSION_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_VEHICLE_CHARGING_SESSION_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* faultyBookingReport(action) {
  try {
    const response = yield call(API.faultyBookingReport, action.payload);
    yield put({ type: FAULTY_BOOKING_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FAULTY_BOOKING_REPORT_FAIL, payload: { error: e } });
  }
}

function* downloadFaultyBookingReport(action) {
  try {
    const response = yield call(API.downloadFaultyBookingReport, action.payload);
    yield put({ type: DOWNLOAD_FAULTY_BOOKING_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_FAULTY_BOOKING_REPORT_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* chargingSessionReport(action) {
  try {
    const response = yield call(API.chargingSessionReport, action.payload);
    yield put({ type: CHARGING_SESSION_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CHARGING_SESSION_REPORT_FAIL, payload: { error: e } });
  }
}

function* downloadChargingSessionReport(action) {
  try {
    const response = yield call(API.downloadChargingSessionReport, action.payload);
    yield put({ type: DOWNLOAD_CHARGING_SESSION_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_CHARGING_SESSION_REPORT_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* plantLoadFactorReport(action) {
  try {
    const response = yield call(API.plantLoadFactorReport, action.payload);
    yield put({ type: PLANT_LOAD_FACTOR_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: PLANT_LOAD_FACTOR_REPORT_FAIL, payload: { error: e } });
  }
}

function* customerWalletTransactionReport(action) {
  try {
    const response = yield call(API.customerWalletTransactionReport, action.payload);
    yield put({ type: CUSTOMER_WALLET_TRANSACTION_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CUSTOMER_WALLET_TRANSACTION_REPORT_FAIL, payload: { error: e } });
  }
}

function* downloadCustomerWalletTransactionReport(action) {
  try {
    const response = yield call(API.downloadCustomerWalletTransactionReport, action.payload);
    yield put({ type: DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT_FAIL, payload: { error: e } });
  }
}

function* partnerChargingSummary(action) {
  try {
    const response = yield call(API.partnerChargingSummary, action.payload);
    yield put({ type: PARTNER_CHARGING_SUMMARY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: PARTNER_CHARGING_SUMMARY_FAIL, payload: { error: e } });
  }
}

function* downloadPartnerChargingSummary(action) {
  try {
    const response = yield call(API.downloadPartnerChargingSummary, action.payload);
    yield put({ type: DOWNLOAD_PARTNER_CHARGING_SUMMARY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_PARTNER_CHARGING_SUMMARY_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* dataReport() {
  yield takeLatest(STATION_UNIT_CONSUMPTION, stationUnitConsumption);
  yield takeLatest(OCPI_BOOKING, ocpiPartnerChargingReport);
  yield takeLatest(DOWNLOAD_OCPI_BOOKING, downloadocpiPartnerChargingReport);
  yield takeLatest(B2B_STATION_CHARGING_HISTORY, b2bStationChargingHistory);
  yield takeLatest(B2C_STATION_CHARGING_HISTORY, b2cStationChargingHistory);
  yield takeLatest(JSON_REQUEST_RESPONSE_REPORT, jsonRequestResponseReport);
  yield takeLatest(WALLET_TRANSACTION_REPORT, walletTransactionReport);
  yield takeLatest(ALL_DATA_REPORT, alldatareport);
  yield takeLatest(HMIL_CUSTOM_CHARGING_REPORT, HMILCustomChargingReport);
  yield takeLatest(BOOKING_HISTORY, bookingHistory);
  yield takeLatest(APP_USER_DETAILS_LIST, appUserDetailsList);
  yield takeLatest(MOST_VEHICLE_CHARGED_LIST, mostVehicleChargedList);
  yield takeLatest(USER_CHARGING_HISTORY, userChargingHistory);
  yield takeLatest(STATION_CHARGING_SUMMARY, stationChargingSummary);
  yield takeLatest(PRIVATE_STATION_CHARGING_SUMMARY, privateStationChargingSummary);
  yield takeLatest(PUBLIC_STATION_CHARGING_SUMMARY, publicStationChargingSummary);
  yield takeLatest(BOOKING_SUMMARY_REPORT, bookingSummaryReport);
  yield takeLatest(MONEY_TRANSACTION_PG_REPORT, moneyTransactionPgReport);
  yield takeLatest(DOWNLOAD_STATION_CHARGING_SUMMARY, downloadStationChargingSummary);
  yield takeLatest(DOWNLOAD_PRIVATE_STATION_CHARGING_SUMMARY, downloadPrivateStationChargingSummary);
  yield takeLatest(DOWNLOAD_PUBLIC_STATION_CHARGING_SUMMARY, downloadPublicStationChargingSummary);
  yield takeLatest(DOWNLOAD_METER_VALUES, downloadMeterValues);
  yield takeLatest(DOWNLOAD_BOOKING_HISTORY, downloadBookingHistory);
  yield takeLatest(DOWNLOAD_USER_CHARGING_HISTORY, downloadUserChargingHistory);
  yield takeLatest(DOWNLOAD_B2C_STATION_CHARGING_HISTORY, downloadB2CStationChargingHistory);
  yield takeLatest(DOWNLOAD_CHARGING_HISTORY, downloadChargingHistory);
  yield takeLatest(DOWNLOAD_B2B_STATION_CHARGING_HISTORY, downloadB2BStationChargingHistory);
  yield takeLatest(DOWNLOAD_MONEY_TRANSACTION_PG_REPORT, downloadMoneyTransactionPGReport);
  yield takeLatest(DOWNLOAD_WALLET_TRANSACTION_REPORT, downloadWalletTransactionReport);
  yield takeLatest(DOWNLOAD_PLANT_LOAD_FACTOR_REPORT, downloadPlantLoadFactorReport);
  yield takeLatest(DOWNLOAD_ALL_DATA_REPORT, downloadalldatareport);
  yield takeLatest(DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT, DownloadHMILCustomChargingReport);
  yield takeLatest(DOWNLOAD_STATION_UNIT_CONSUMPTION, downloadStationUnitConsumption);
  yield takeLatest(CALENDAR_VIEW, calendarView);
  yield takeLatest(STATION_DOWNTIME_REPORT, stationDownTimeReport);
  yield takeLatest(DOWNLOAD_STATION_DOWNTIME_REPORT, downloadStationDownTimeReport);
  yield takeLatest(AC_METER_READING_REPORT, acMeterReadingReport);
  yield takeLatest(DOWNLOAD_AC_METER_READING_REPORT, downloadACMeterReadingReport);
  yield takeLatest(DOWNLOAD_APP_USER_DETAIL_REPORT, downloadappUserDetailList);
  yield takeLatest(DOWNLOAD_JOB_VOUCHER, downloadJobVoucherReport);
  yield takeLatest(DOWNLOAD_GST_VOUCHER, downloadGstVoucherReport);
  yield takeLatest(INVOICE_REPORT, invoiceReport);
  yield takeLatest(DOWNLOAD_INVOICE_REPORT, downloadInvoiceReport);
  yield takeLatest(DOWNLOAD_BOOKING_SUMMARY_REPORT, downloadBookingSummaryReport);
  yield takeLatest(DOWNLOAD_MOST_VEHICLE_CHARGED_LIST, downloadMostVehicleChargedList);
  yield takeLatest(PAYMENT_STATUS_REPORT, paymentStatusReport);
  yield takeLatest(DOWNLOAD_PAYMENT_STATUS_REPORT, downloadPaymentStatusReport);
  yield takeLatest(VEHICLE_CHARGING_SESSION, vehicleChargingSession);
  yield takeLatest(DOWNLOAD_VEHICLE_CHARGING_SESSION, downloadVehicleChargingSession);
  yield takeLatest(DOWNLOAD_FAULTY_BOOKING_REPORT, downloadFaultyBookingReport);
  yield takeLatest(FAULTY_BOOKING_REPORT, faultyBookingReport);
  yield takeLatest(CHARGING_SESSION_REPORT, chargingSessionReport);
  yield takeLatest(DOWNLOAD_CHARGING_SESSION_REPORT, downloadChargingSessionReport);
  yield takeLatest(PLANT_LOAD_FACTOR_REPORT, plantLoadFactorReport);
  yield takeLatest(CUSTOMER_WALLET_TRANSACTION_REPORT, customerWalletTransactionReport);
  yield takeLatest(DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT, downloadCustomerWalletTransactionReport);
  yield takeLatest(PARTNER_CHARGING_SUMMARY, partnerChargingSummary);
  yield takeLatest(DOWNLOAD_PARTNER_CHARGING_SUMMARY, downloadPartnerChargingSummary);
}
export default dataReport;
