import { get, post, patch, download, downloadFile } from 'utils/api';
import { CUSTOMERS, USERS, WIDGETS } from './endpointURLs';

export const walletTransaction = (data) => {
  return post(`/wallets`, data);
};

export const getCustomerProfile = (id) => {
  return get(`${CUSTOMERS}/profile/${id}`);
};

export const getCustomerBooking = (data) => {
  const id = data.id;
  delete data['id'];
  return get(`${CUSTOMERS}/bookings/${id}`, data);
};

export const getCustomerTransaction = (data) => {
  const id = data.id;
  delete data['id'];
  return get(`${CUSTOMERS}/transactions/${id}`, data);
};

export const getFaultyCustomerTransaction = (data) => {
  const id = data.id;
  delete data['id'];
   return get(`${CUSTOMERS}/Faultytransactions/${id}`, data);
};


export const getCustomers = (data) => {
  return get(`${USERS}${CUSTOMERS}`, data);
};

export const getWidgets = (data) => {
  return get(`${USERS}${WIDGETS}`, data);
};

export const updateCustomer = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`${CUSTOMERS}/${id}`, data);
};

export const downloadCustomerBooking = (data) => {
  const id = data.id;
  delete data['id'];
  return download(`${CUSTOMERS}/bookings/${id}`, data);
};

export const getAllChargeCoinTransaction = (data) => {
  let page = data.page ? data.page : 1;
  return post(`/charge_coin/viewtransactions?page=${page}`, { id: data.id });
};

export const downloadCustomerTransactions = (data) => {
  const id = data.id;
  delete data['id'];
  return download(`${CUSTOMERS}/transactions/${id}`, data);
};

export const downloadFaultyCustomerTransactions = (data) => {
  const id = data.id;
  delete data['id'];
  return download(`${CUSTOMERS}/Faultytransactions/${id}`, data);
};

export const syncWallet = (data) => {
  return get(`/wallets/reconcileRazorPayOrder/${data?.id}`);
};

export const calculateCustomerBooking = (data) => {
  const result = post(`/charger_bookings/calculateCMSBooking`, data);
  return result;
};

export const createCustomerBooking = (data) => {
  const result = post(`/charger_bookings/newCMSBooking`, data);
  return result;
};

export const rescheduleCustomerBooking = (data) => {
  const id = data.id;
  delete data['id'];
  return post(`/charger_bookings/CMSreschedule/${id}`, data);
};

export const cancelCustomerBooking = (data) => {
  const id = data.id;
  delete data['id'];
  return post(`/charger_bookings/CMScancel/${id}`, data);
};

export const getRoles = (data) => {
  return get(`/roles/customer`, data);
};

export const resetOTPLimit = (data) => {
  return post(`/otps/reset-count`, data);
}

export const updateCustomerVehicle = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/vehicles/editevcc/${id}`, data);
};

export const downloadPaymentReceipt = (data) => {
  const id = data.id;
  delete data['id'];
  return downloadFile(`/wallets/paymentreceipt/${id}` , data);
};

export const customer = {
  getCustomers,
  getWidgets,
  walletTransaction,
  getCustomerTransaction,
  getFaultyCustomerTransaction,
  getCustomerBooking,
  getCustomerProfile,
  updateCustomer,
  downloadCustomerBooking,
  getAllChargeCoinTransaction,
  downloadCustomerTransactions,
  downloadFaultyCustomerTransactions,
  syncWallet,
  calculateCustomerBooking,
  createCustomerBooking,
  rescheduleCustomerBooking,
  cancelCustomerBooking,
  getRoles,
  resetOTPLimit,
  updateCustomerVehicle,
  downloadPaymentReceipt
};
