export const FETCH_OWNERSHIP_TYPE = 'FETCH_OWNERSHIP_TYPE';
export const FETCH_OWNERSHIP_TYPE_SUCCESS = 'FETCH_OWNERSHIP_TYPE_SUCCESS';
export const FETCH_OWNERSHIP_TYPE_FAIL = 'FETCH_OWNERSHIP_TYPE_FAIL';

export const GET_OWNERSHIP_TYPE = 'GET_OWNERSHIP_TYPE_MAKE';
export const GET_OWNERSHIP_TYPE_SUCCESS = 'GET_OWNERSHIP_TYPE_SUCCESS';
export const GET_OWNERSHIP_TYPE_FAIL = 'GET_OWNERSHIP_TYPE_FAIL';

export const CREATE_OWNERSHIP_TYPE = 'CREATE_OWNERSHIP_TYPE';
export const CREATE_OWNERSHIP_TYPE_SUCCESS = 'CREATE_OWNERSHIP_TYPE_SUCCESS';
export const CREATE_OWNERSHIP_TYPE_FAIL = 'CREATE_OWNERSHIP_TYPE_FAIL';

export const UPDATE_OWNERSHIP_TYPE = 'UPDATE_OWNERSHIP_TYPE';
export const UPDATE_OWNERSHIP_TYPE_SUCCESS = 'UPDATE_OWNERSHIP_TYPE_SUCCESS';
export const UPDATE_OWNERSHIP_TYPE_FAIL = 'UPDATE_OWNERSHIP_TYPE_FAIL';

export const DELETE_OWNERSHIP_TYPE = 'DELETE_OWNERSHIP_TYPE';
export const DELETE_OWNERSHIP_TYPE_SUCCESS = 'DELETE_OWNERSHIP_TYPE_SUCCESS';
export const DELETE_OWNERSHIP_TYPE_FAIL = 'DELETE_OWNERSHIP_TYPE_FAIL';
