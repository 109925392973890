import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  FETCH_OWNERSHIP_TYPE,
  FETCH_OWNERSHIP_TYPE_SUCCESS,
  FETCH_OWNERSHIP_TYPE_FAIL,
  GET_OWNERSHIP_TYPE,
  GET_OWNERSHIP_TYPE_SUCCESS,
  GET_OWNERSHIP_TYPE_FAIL,
  CREATE_OWNERSHIP_TYPE,
  CREATE_OWNERSHIP_TYPE_SUCCESS,
  CREATE_OWNERSHIP_TYPE_FAIL,
  UPDATE_OWNERSHIP_TYPE,
  UPDATE_OWNERSHIP_TYPE_SUCCESS,
  UPDATE_OWNERSHIP_TYPE_FAIL,
  DELETE_OWNERSHIP_TYPE,
  DELETE_OWNERSHIP_TYPE_SUCCESS,
  DELETE_OWNERSHIP_TYPE_FAIL,
} from 'actions/ownershipType';

function* fetchOwnershipType(action) {
  try {
    const response = yield call(API.getOwnershipType, action.payload);
    yield put({ type: FETCH_OWNERSHIP_TYPE_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_OWNERSHIP_TYPE_FAIL, payload: { error: e } });
  }
}

function* getOwnershipTypeById(action) {
  try {
    const response = yield call(API.getOwnershipTypeById, action.payload);
    yield put({ type: GET_OWNERSHIP_TYPE_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_OWNERSHIP_TYPE_FAIL, payload: { error: e } });
  }
}

function* createOwnershipType(action) {
  try {
    const response = yield call(API.addOwnershipType, action.payload);
    yield put({ type: CREATE_OWNERSHIP_TYPE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_OWNERSHIP_TYPE_FAIL, payload: { error: e } });
  }
}

function* updateOwnershipType(action) {
  try {
    const response = yield call(API.updateOwnershipType, action.payload);
    yield put({ type: UPDATE_OWNERSHIP_TYPE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_OWNERSHIP_TYPE_FAIL, payload: { error: e } });
  }
}

function* deleteOwnershipType(action) {
  try {
    const response = yield call(API.deleteOwnershipType, action.payload);
    yield put({ type: DELETE_OWNERSHIP_TYPE_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_OWNERSHIP_TYPE_FAIL, payload: { error: e } });
  }
}

function* OwnershipType() {
  yield takeLatest(FETCH_OWNERSHIP_TYPE, fetchOwnershipType);
  yield takeLatest(GET_OWNERSHIP_TYPE, getOwnershipTypeById);
  yield takeLatest(CREATE_OWNERSHIP_TYPE, createOwnershipType);
  yield takeLatest(UPDATE_OWNERSHIP_TYPE, updateOwnershipType);
  yield takeLatest(DELETE_OWNERSHIP_TYPE, deleteOwnershipType);
}

export default OwnershipType;
