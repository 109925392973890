import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
// import { Row, Col } from 'react-bootstrap';
// import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import _ from 'lodash';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import * as Yup from 'yup';
import { CREATE_RFID, UPDATE_RFID } from 'actions/rfid';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import TextInput from 'components/inputs/Input';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const RfidEditList = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { rfidGroupId } = useParams();
  const { isEdit, onClose = _.noop(), onSuccess = _.noop() } = props;

  const rfidDetail = useSelector((state) => state.rfid.rfidDetail);
  const isLoading = useSelector((state) => state.rfid.isLoading);

  const addRfid = useCallback((data) => {
    dispatch({
      type: CREATE_RFID,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          onSuccess({ group: rfidGroupId });
          onClose();
        }
      },
    });
  }, []);

  const updateRfid = useCallback((data) => {
    dispatch({
      type: UPDATE_RFID,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  }, []);

  const RfidSchema = Yup.object().shape({
    code: Yup.string().required(t('rfidEditList.code')).strict(true).trim(t('rfidEditList.space')).max(100, t('rfidEditList.maxlimit')),
    card_number: Yup.string().required(t('rfidEditList.cardNumber')).strict(true).trim(t('rfidEditList.space')).max(100, t('rfidEditList.maxlimit')),
    date_of_issuance: Yup.date().nullable(),
    expiry_date: Yup.date().nullable().min(Yup.ref('date_of_issuance'), t('rfidEditList.expiryDateText')),
  });

  const initialValues = isEdit
    ? {
        ...rfidDetail,
        date_of_issuance: rfidDetail.date_of_issuance ? moment(rfidDetail.date_of_issuance).format('YYYY-MM-DD') : '',
        expiry_date: rfidDetail.expiry_date ? moment(rfidDetail.expiry_date).format('YYYY-MM-DD') : '',
        group: rfidGroupId,
        card_number: _.get(rfidDetail, 'card_number', ''),
      }
    : {
        code: '',
        card_number: '',
        date_of_issuance: '',
        expiry_date: '',
        group: rfidGroupId,
        // offer_expiry_date: '',
      };
  return (
    <React.Fragment>
      <div className="rfid-edit-list-page__main">
        <Card>
          <div className="rfid-edit-list-header__block">
            <span>
              {isEdit ? 'Update' : 'Add'} {t('rfidEditList.title')}
            </span>
            {/* <div className="close___btn">
              <MdClose size={30} color="#BE210B" onClick={onClose} />
            </div> */}
          </div>
          <div className="rfid-edit-list-body__block">
            <div className="rfid-edit-list--block">
              <Formik
                enableReinitialize={!!isEdit}
                initialValues={initialValues}
                validationSchema={RfidSchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (isEdit) {
                    const editRfidData = _.omit(values, ['status', 'tenant', 'comments', 'offer_enabled', 'user', 'first_name', 'last_name']);
                    updateRfid(editRfidData);
                  } else {
                    const addRfidData = {
                      ...values,
                      date_of_issuance: values.date_of_issuance ? moment(values.date_of_issuance).format('YYYY-MM-DD') : '',
                      expiry_date: values.expiry_date ? moment(values.expiry_date).format('YYYY-MM-DD') : '',
                      // offer_expiry_date: moment(values.offer_expiry_date).format('YYYY-MM-DD'),
                    };
                    addRfid(addRfidData);
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="rfid-edit-list-table">
                      <div className="table-responsive">
                        <table className="record-list-table">
                          <thead>
                            <tr>
                              <th>
                                <div className="sorting">
                                  <span> {t('rfidEditList.srNo')}</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{t('rfidEditList.rfidCardNo')}</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{t('rfidEditList.rfidTag')}</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{t('rfidEditList.dateOfIssuance')}</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{t('rfidEditList.expiryDate')}</span>
                                </div>
                              </th>
                              {/*<th>*/}
                              {/*  <div className="sorting">*/}
                              {/*    <span>Offer Expiry Date</span>*/}
                              {/*  </div>*/}
                              {/*</th>*/}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1.</td>
                              <td>
                                <TextInput
                                  isRequired
                                  name="card_number"
                                  value={values.card_number}
                                  onChange={handleChange}
                                  error={errors.card_number && touched.card_number ? errors.card_number : null}
                                />
                              </td>
                              <td>
                                <TextInput
                                  isRequired
                                  name="code"
                                  value={values.code}
                                  onChange={handleChange}
                                  error={errors.code && touched.code ? errors.code : null}
                                />
                              </td>
                              <td>
                                <TextInput
                                  type="date"
                                  name="date_of_issuance"
                                  value={values.date_of_issuance}
                                  onChange={handleChange}
                                  error={errors.date_of_issuance && touched.date_of_issuance ? errors.date_of_issuance : null}
                                />
                              </td>
                              <td>
                                <TextInput
                                  type="date"
                                  name="expiry_date"
                                  value={values.expiry_date}
                                  onChange={handleChange}
                                  error={errors.expiry_date && touched.expiry_date ? errors.expiry_date : null}
                                />
                              </td>
                              {/*<td>*/}
                              {/*  <TextInput*/}
                              {/*    isRequired*/}
                              {/*    type="date"*/}
                              {/*    name="offer_expiry_date"*/}
                              {/*    value={values.offer_expiry_date}*/}
                              {/*    onChange={handleChange}*/}
                              {/*    error={errors.offer_expiry_date && touched.offer_expiry_date ? errors.offer_expiry_date : null}*/}
                              {/*  />*/}
                              {/*</td>*/}
                            </tr>
                          </tbody>
                        </table>
                        <div className="rfid-edit-list-btn__block">
                          <span>
                            <Button type="submit" disabled={isSubmitting || isLoading} className="rfid-edit-list_btn">
                              {isEdit ? 'Update' : 'Add'} {t('button.RFID')}
                            </Button>
                          </span>
                          <span className="cancel-button-block">
                            <Button className="cancel____btn" onClick={onClose}>
                              {t('button.cancel')}
                            </Button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
RfidEditList.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default RfidEditList;
