import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation, Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createPromiseAction } from '@adobe/redux-saga-promise';
import { FETCH_PROFILE, GET_PROFILE_SUCCESS } from 'actions/profile';
import MediaQuery from 'react-responsive';
import MainLayout from 'components/layout/index';
import { onMessageListener } from '../../firebase';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { MessagePopup } from 'App';
import { GET_DASHBOARD_DATA } from 'actions/dashboard';
import { FETCH_CHARGER_BY_STATION, GET_CHARGER } from 'actions/charger';
import { FETCH_NOTIFICATION } from 'actions/notification';
import moment from 'moment';
import { MANAGE_ROLE } from 'actions/role';
import { cookie } from 'utils/cookies/cookies';
import PropTypes from 'prop-types';

export const RealTimeData = createContext();
export const NavContext = createContext();

const parseQueryString = (queryString) => {
  queryString = queryString.replace(/^\?/, '');
  const pairs = queryString.split('&');
  const params = {};
  pairs.forEach((pair) => {
    const [key, value] = pair.split('=');
    params[key] = value;
  });
  return params;
};

const renderRoutes = (routes) => {
  return routes
    .map((route, index) => {
      if (!route || typeof route !== 'object') {
        console.error('Invalid route object:', route);
        return null;
      }
      if (!route.path || typeof route.path !== 'string') {
        console.error('Invalid route path:', route.path);
        return null;
      }
      if (!route.component || typeof route.component !== 'function') {
        console.error('Invalid route component:', route.component);
        return null;
      }
      return <Route key={`${route.path}-${index}`} path={route.path} element={<route.component {...route} />} />;
    })
    .filter(Boolean); // Remove any null elements
};

const PrivateRoute = ({ routes }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [userT, setUserT] = useState();
  const [realTimeMessageType, setRealTimeMessageType] = useState(false);
  const userData = useSelector((state) => state.profile.userProfile);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);
  const excludedModules = useSelector((state) => state.manageRole?.excludedModules);
  const navigateTo = (path, state) => {
    if (path) {
      const queryParams = parseQueryString(path);
      const searchParams = new URLSearchParams(location.search);
      let paramsLength = Object.keys(queryParams).length;
      let tenant = searchParams.get('tenant');
      let newPath = path;
      let lastKey;
      for (const key in queryParams) {
        lastKey = key;
      }
      if (tenant && paramsLength >= 1) {
        if (queryParams[lastKey]) {
          newPath = newPath + '&tenant=' + tenant;
        } else {
          newPath = newPath + '?tenant=' + tenant;
        }
      }
      if (tenant && paramsLength == 0 && queryParams[lastKey]) {
        newPath = newPath + '?tenant=' + tenant;
      }
      navigate(newPath, state);
    }
  };

  const isAllowed = useCallback(
    (key) => {
      const excludedModulesids = excludedModules.map((exMod) => exMod.id);
      const allowModulesKeys =
        rolePermissionData?.[0]?.modules?.filter((module) => !excludedModulesids.includes(module.id))?.map((module) => module.key) || [];

      return allowModulesKeys.length > 0 && allowModulesKeys.includes(key);
    },
    [JSON.stringify(rolePermissionData), JSON.stringify(excludedModules)]
  );

  const getProfile = useCallback((data) => {
    const fetchPostAction = createPromiseAction(FETCH_PROFILE);
    return dispatch(fetchPostAction(data));
  }, []);

  const setUser = useCallback((data) => dispatch({ type: GET_PROFILE_SUCCESS, payload: data }), []);

  const getDashboard = useCallback(() => {
    dispatch({ type: GET_DASHBOARD_DATA });
  }, []);

  const getChargers = useCallback((data = {}) => {
    dispatch({ type: FETCH_CHARGER_BY_STATION, payload: data });
  }, []);

  const getChargerDetail = useCallback((id) => {
    dispatch({ type: GET_CHARGER, payload: id });
  }, []);

  const getGeneralNotification = useCallback((data = {}) => {
    dispatch({ type: FETCH_NOTIFICATION, payload: data });
  }, []);

  useEffect(() => {
    if (userT?.role !== undefined && userT?.role !== '') {
      dispatch({ type: MANAGE_ROLE, payload: { name: userT.role } });
    }
  }, [userT, dispatch]);

  const onMessageReceived = useCallback(
    (payload) => {
      setRealTimeMessageType(payload.data.type);
      if (payload.data.type === 'update_dashboard') {
        if (location.pathname === '/dashboard') {
          getDashboard();
        } else if (payload.data.for === 'charger' && location.pathname === `/stations/${get(payload, 'data.station')}`) {
          getChargers({ id: get(payload, 'data.station') });
        } else if (payload.data.for === 'charger' && location.pathname === `/charger/${get(payload, 'data.charger')}`) {
          getChargerDetail(get(payload, 'data.charger'));
        }
      } else {
        if (location.pathname === '/notification') {
          const allNotification = {
            from: moment(moment().startOf('month')).format('YYYY-MM-DD HH:mm:ss'),
            to: moment(moment(new Date()).endOf('day')).format('YYYY-MM-DD HH:mm:ss'),
          };
          getGeneralNotification(allNotification);
        }
        const title = get(payload, 'data.title', '');
        const description = get(payload, 'data.description', '');
        const options = {
          autoClose: 6000,
          type: toast.TYPE.INFO,
          position: toast.POSITION.TOP_RIGHT,
          pauseOnHover: true,
        };
        if (title) {
          toast(<MessagePopup title={title} description={description} />, options);
        }
      }
    },
    [location]
  );

  onMessageListener(onMessageReceived)
    .then()
    .catch((err) => console.error('Failed: ', err));

  const tenantId = new URLSearchParams(location.search).get('tenant');
  const tenantSessionToken = localStorage.getItem(`${tenantId}_token`);
  let token = tenantId ? tenantSessionToken : cookie.get('token');

  useEffect(() => {
    if (token) {
      getProfile()
        .then((res) => {
          const user2 = res.data;
          setUserT(user2);
          setUser(user2);
        })
        .catch((err) => {
          console.error(err);
          cookie.clean();
          navigate('/login');
        });
    } else if (!token || !userData) {
      navigate('/login');
    }
  }, []);

  return (
    <NavContext.Provider value={{ navigateTo, isAllowed }}>
      <RealTimeData.Provider value={realTimeMessageType}>
        <MediaQuery minWidth={800}>
          {(matches) => (
            <MainLayout showToggle={!matches}>
              <Routes>{renderRoutes(routes)}</Routes>
            </MainLayout>
          )}
        </MediaQuery>
      </RealTimeData.Provider>
    </NavContext.Provider>
  );
};

PrivateRoute.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      component: PropTypes.elementType.isRequired,
      permission: PropTypes.string,
      moduleKey: PropTypes.string,
      roles: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
};

export default PrivateRoute;
