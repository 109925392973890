import { DOWNLOAD_PAYMENT_RECEIPT , DOWNLOAD_PAYMENT_RECEIPT_SUCCESS , DOWNLOAD_PAYMENT_RECEIPT_FAIL} from 'actions/customer';


const initialValues = {
  isLoading: true,
  receiptDetails: '',
};

const paymentReceiptReducer = (state = initialValues, { type, payload }) => {
  switch (type) {
    case DOWNLOAD_PAYMENT_RECEIPT: {
      return { ...state, isLoading: true };
    }

    case DOWNLOAD_PAYMENT_RECEIPT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        receiptDetails: payload,
      };
    }

    case DOWNLOAD_PAYMENT_RECEIPT_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }

};

export default paymentReceiptReducer;
