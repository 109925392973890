import React, { useCallback, useContext, useEffect, useState } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { BiSort } from 'react-icons/bi';
import * as _ from 'lodash';
import { BsSearch } from 'react-icons/bs';
import ReactPagination from 'components/general/ReactPagination';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_RFID_REQUEST, GET_RFID_REQUEST, DOWNLOAD_RFID_REQUEST } from 'actions/rfidRequest';
import { startsWith } from 'components/common/utils';
import { Col, Modal, Row, Spinner } from 'react-bootstrap';
import SearchBox from 'components/general/SearchBox';
import Button from 'components/inputs/Button';
import RfidRequestModal from './RfidRequestModal';
import { BsDownload } from 'react-icons/bs';
import fileDownload from 'js-file-download';
import DateTimePicker from 'components/inputs/DateTimePicker';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const RfidRequests = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isAllowed } = useContext(NavContext);
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [allRfidRequestData, setAllRfidRequestData] = useState([]);
  const [startDate, setStartDate] = useState(moment(new Date()).startOf('day'));
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'));
  const [searchText, setSearchText] = useState('');
  const [showModal, setShowModal] = useState(false);

  const rfidRequests = useSelector((state) => state.rfidRequest.rfidRequests);
  const isLoader = useSelector((state) => state.rfidRequest.isLoading);
  const page = useSelector((state) => state.rfidRequest.page);
  const totalData = useSelector((state) => state.rfidRequest.total);
  const limit = useSelector((state) => state.rfidRequest.limit);
  const totalPages = useSelector((state) => state.rfidRequest.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const getAllRfidRequest = useCallback((data = { page: 1 }) => {
    dispatch({ type: FETCH_RFID_REQUEST, payload: data });
  }, []);

  const getRfidRequestById = useCallback((id = '') => {
    dispatch({ type: GET_RFID_REQUEST, payload: id });
  }, []);

  const downloadRfidRequest = useCallback(() => {
    const data = {
      excel: true,
      from: moment(startDate).utc(),
      to: moment(endDate).utc(),
    };
    dispatch({
      type: DOWNLOAD_RFID_REQUEST,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            fileDownload(res.data, `${'Rfid Request'}.xlsx`);
          }
        }
      },
    });
  }, [startDate, endDate]);

  const downloadFile = () => {
    downloadRfidRequest();
  };

  // useEffect(() => {
  //   getAllRfidRequest();
  // }, []);

  const handleSearch = () => {
    getAllRfidRequest({});
  };

  useEffect(() => {
    const filterDateData = {
      page: 1,
      from: moment(startDate).utc(),
      to: moment(endDate).utc(),
    };
    getAllRfidRequest(filterDateData);
  }, [startDate, endDate]);

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getAllRfidRequest(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getAllRfidRequest(data);
    }
  };

  useEffect(() => {
    setAllRfidRequestData(
      _.filter(
        rfidRequests,
        (item) =>
          startsWith(_.get(item, 'first_name'), searchText) ||
          startsWith(_.get(item, 'last_name', ''), searchText) ||
          startsWith(_.get(item, 'street_name', ''), searchText) ||
          startsWith(_.get(item, 'apartment_name', ''), searchText) ||
          startsWith(_.get(item, 'city', ''), searchText) ||
          startsWith(_.get(item, 'state', ''), searchText) ||
          startsWith(_.get(item, 'zip_code', ''), searchText) ||
          startsWith(_.get(item, 'status', ''), searchText)
        // startsWith(_.get(item, 'user_comment', ''), searchText)
      )
    );
  }, [rfidRequests, searchText]);

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (startDate && endDate) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          page: page.selected + 1,
        };
        getAllRfidRequest(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getAllRfidRequest(data);
      }
    },
    [sortByItem, startDate, endDate]
  );

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('header.rfidRequests')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="rfid-request_main">
              <Card>
                <div className="rfid-request__block">
                  <Row className="rfid-request-search-box">
                    <Col lg={4} className="groupList-search-inputBox">
                      <SearchBox
                        preIcon={<BsSearch />}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            handleSearch();
                          }
                        }}
                      />
                    </Col>
                    <Col lg={7}>
                      <DateTimePicker
                        onChangeOfStartDate={(item) => setStartDate(item)}
                        onChangeOfEndDate={(item) => setEndDate(item)}
                        initialValueOfStartDate={startDate}
                        initialValueOfEndDate={endDate}
                      />
                    </Col>
                    <Col lg={1} className="excel-icon--block">
                      {isLoading ? (
                        <div className="loader--block">
                          <Spinner as="span" animation="border" size="sm" role="status" />
                        </div>
                      ) : (
                        isAllowed('downloadRFIDRequests') && (
                          <div className="report-excel-icon">
                            <BsDownload title="Download" size={34} color={'#3c7cdd'} onClick={downloadFile} />
                          </div>
                        )
                      )}
                    </Col>
                  </Row>
                  <div className="rfid-request-table">
                    <div className="table-responsive">
                      <table className="record-list-table">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidRequestModal.srNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidRequestModal.firstName')}</span>
                                <span className="ico" onClick={() => handleSorting('first_name', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidRequestModal.lastName')}</span>
                                <span className="ico" onClick={() => handleSorting('last_name', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            {/*<th>*/}
                            {/*  <div className="sorting">*/}
                            {/*    <span>User Comment</span>*/}
                            {/*    <span className="ico" onClick={() => handleSorting('user_comment', sortByItem)}>*/}
                            {/*      <BiSort size={15} />*/}
                            {/*    </span>*/}
                            {/*  </div>*/}
                            {/*</th>*/}
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidRequestModal.streetName')}</span>
                                <span className="ico" onClick={() => handleSorting('street_name', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidRequestModal.apartmentName')}</span>
                                <span className="ico" onClick={() => handleSorting('apartment_name', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidRequestModal.city')}</span>
                                <span className="ico" onClick={() => handleSorting('city', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidRequestModal.state')}</span>
                                <span className="ico" onClick={() => handleSorting('state', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidRequestModal.zipCode')}</span>
                                <span className="ico" onClick={() => handleSorting('zip_code', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidRequestModal.status')}</span>
                                <span className="ico" onClick={() => handleSorting('status', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidRequestModal.customerName')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidRequestModal.customerNumber')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidRequestModal.dateTime')}</span>
                              </div>
                            </th>
                            {isAllowed('modifyRFIDRequests') && (
                              <th className="action-col">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidRequestModal.action')}</span>
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={13} className="border-0">
                                <div className="loader--block">
                                  <Skeleton height={15} width={100} />
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(allRfidRequestData) ? (
                            <tr>
                              <td colSpan={14} className="border-0">
                                <div className="empty-data-block"> {t('rfidRequestModal.noData')}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(allRfidRequestData, (item, key) => {
                              const serial_num = limit * (page - 1) + key;
                              return (
                                <tr key={`rfid-${serial_num}`}>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}.</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'first_name', '')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'last_name', '')}</td>
                                  {/*<td>{_.get(item, 'user_comment', '')}</td>*/}
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'street_name', '')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'apartment_name', '')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'city', '')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'state', '')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'zip_code', '')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'status', '')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'customer_involved.name', '')}</td>
                                  <td>
                                    {' '}
                                    +{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'customer_involved.country_code', '')}
                                    {isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'customer_involved.phone', '')}
                                  </td>
                                  <td>{moment(_.get(item, 'createdAt', '-')).format('DD/MM/YYYY H:mm:ss')}</td>
                                  {isAllowed('modifyRFIDRequests') && (
                                    <td>
                                      {item.status !== 'Approved' && (
                                        <div>
                                          {isLoader ? (
                                            <Skeleton height={15} width={100} />
                                          ) : (
                                            <Button
                                              className="rfid-request-btn"
                                              onClick={() => {
                                                setShowModal(true);
                                                getRfidRequestById(_.get(item, 'id', ''));
                                              }}
                                            >
                                              {t('button.assign')}
                                            </Button>
                                          )}
                                        </div>
                                      )}
                                    </td>
                                  )}
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allRfidRequestData) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </SkeletonTheme>
        {showModal && (
          <Modal show={showModal} size="lg" centered>
            <RfidRequestModal onClose={handleCloseModal} />
          </Modal>
        )}
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </React.Fragment>
  );
};

export default RfidRequests;
